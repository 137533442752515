import { raf } from '@lib/global.js';
import mount from '@lib/mount.js';

const handleSearchAction = async function () {
  import(
    /* webpackChunkName: "vanillajs-autocomplete" */
    '@components/autocomplete.js'
  ).then((autocomplete) => autocomplete.default());
};

export default (progressiveStart) => {
  const header = document.getElementById('mobile-header');
  if (!header) return;

  return import(
    /* webpackChunkName: "header" */
    '@apps/mobile/header.js'
  )
    .then((header) => mount(header, '#mobile-header'))
    .then(() => {
      raf(() => {
        import(
          /* webpackChunkName: "utm" */
          '@lib/utm.js'
        ).then((utm) => {
          utm.default.get();
        });
      });
    })
    .then(() => {
      window.document.addEventListener('touchstart', progressiveStart, false);
      window.document.addEventListener('scroll', progressiveStart, false);
    })
    .then(() => {
      setTimeout(() => {
        const autocompleteInputCtas = [].slice.call(
          document.querySelectorAll('.autocomplete-input-cta')
        );
        autocompleteInputCtas.forEach((element) => {
          element.addEventListener('click', handleSearchAction, {
            passive: true
          });
          element.addEventListener('focus', handleSearchAction, {
            passive: true
          });
        });

        //Fallback to prevent user clicking on input when page is still loading
        if (
          autocompleteInputCtas.length &&
          document.activeElement == autocompleteInputCtas[0]
        ) {
          handleSearchAction();
        }
      }, 300);
    });
};
