'use strict';

import { initGlobal } from '@lib/global';
import pl_events from '@lib/pubSub';
import { initLazyLoad } from '@bootstrap/shared/lazyload';
import onLoadScripts from '@bootstrap/shared/onLoadScripts';
import user from '@bootstrap/shared/userComponent';
import search from '@apps/shared/catalog/search';
import dynamicYield from '@bootstrap/shared/dynamicYield';
import mparticleInit from '@bootstrap/shared/mparticle';
import { verifyWebstoreContext } from '@lib/webstore.js';
import { Cookie, FINGERPRINT } from '@lib/cookies';
import { removePasswordLocalCredential } from '@services/loginService';

export const beforeLoad = () => {
  let promises = [initGlobal(), pl_events, mparticleInit(), dynamicYield()];
  return Promise.allSettled(promises);
};

export const afterLoadScripts = () => {
  const promises = [user(), search()];

  const loadGtm = () => {
    if (window.tagManagerEnabled) {
      import('@bootstrap/shared/gtm').then((gtm) => gtm.default());
    }
  };

  const fingerprintLoad = () => {
    if (!Cookie.get(FINGERPRINT)) {
      import('@fingerprintjs/fingerprintjs').then((FingerprintJS) => {
        FingerprintJS.load()
          .then((fp) => fp.get())
          .then((result) => {
            Cookie.set(FINGERPRINT, result.visitorId);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  };

  return Promise.all(promises)
    .then(onLoadScripts())
    .then(() => initLazyLoad())
    .then(() => loadGtm())
    .then(() => verifyWebstoreContext())
    .then(() => fingerprintLoad())
    .then(() => removePasswordLocalCredential());
};

export const afterLoad = () => {
  if (document.readyState === 'complete') {
    afterLoadScripts();
  } else {
    window.addEventListener('load', () => {
      afterLoadScripts();
    });
  }
};
