'use strict';

import mount from '@lib/mount.js';
import { beforeLoad, afterLoad } from '@bootstrap/mobile/default';

const loadApp = () => {
  return import(
    /* webpackChunkName: "mobile-error" */
    '@apps/shared/error/notFound.js'
  ).then((app) => mount(app, '#not-found-app'));
};

beforeLoad().then(loadApp).then(afterLoad);
