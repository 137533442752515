import mount from '@lib/mount.js';

export default () => {
  const searchId = document.getElementById('search-component');
  if (!searchId) return;
  import(
    /* webpackChunkName: "search" */
    '@apps/shared/search.js'
  ).then((search) => mount(search, '#search-component'));
};
